<template>
  <div class="shipment-details max-width">
    <div class="max-width" v-if="!isEdit">
      <v-row>
        <!-- <v-col cols="12" md="6">
          <div class="map-cover w-100 h-100">
            <GmapMap
              :center="{ lat: 10, lng: 10 }"
              :zoom="10"
              style="width: 100%; height: 400px"
            >
            </GmapMap>
          </div>
        </v-col> -->
        <div class="w-100 d-flex justify-space-between flex-wrap mt-5 mx-5 align-center">
          <div>
            <span class="d-block font-30 font-500 primary--text"
              >{{ $t("trip") }} {{ $t("from") }} {{ item.origin_country }}
              {{ $t("to") }} {{ item.destination_country }}</span
            >
          </div>
          <div>
            <div class="d-flex align-center justify-end" v-if="!isEdit">
              <v-btn
                class="c-btn mb-5 mx-2"
                color="secondary"
                height="40"
                @click="dialog = true"
              >
                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
              <v-btn
                class="c-btn mb-5 mx-1"
                color="secondary"
                height="40"
                @click="editHandler"
              >
                <img
                  :src="require('@/assets/images/global/edit2.png')"
                  width="25"
                  alt=""
                />
                <!-- <v-icon color="white">mdi-edit</v-icon> -->
              </v-btn>
            </div>
          </div>
        </div>
        <v-col cols="12" md="6">
          <!-- img & title -->
          <v-row class="my-4" align="center">
            <v-col cols="12">
              <div class="d-flex align-center justify-space-around">
                <div>
                  <span>{{ $t("from") }} </span>
                  <h3>{{ item.origin_city }}</h3>
                </div>
                <div>
                  <img
                    :src="require('@/assets/images/global/airpot.png')"
                    width="25"
                    height="25"
                  />
                </div>
                <div>
                  <span>{{ $t("to") }} </span>
                  <h3>{{ item.destination_city }}</h3>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="w-100 text-center">
                <v-img
                  class="object-cover mx-auto radius-5"
                  :src="item.bringer_image"
                  alt=""
                />
              </div>
            </v-col>
            <v-col
              cols="6"
              align=""
              style="
                height: 250px;
                display: flex;
                flex-direction: column;
                align-content: space-between;
                justify-content: space-between;
              "
            >
              <div class="d-flex">
                <span class="grey--text font-25 font-weight-black"
                  >{{ $t("name") }} :</span
                >
                <span class="font-25 d-block mx-3 primary--text pl-2"
                  >{{ item.bringer_name }}
                </span>
              </div>

              <!-- rate -->
              <v-rating
                :value="+item.rate"
                readonly
                background-color="darkgray"
                empty-icon="mdi-star"
                color="yellow"
                size="14"
              ></v-rating>
              <div class="d-flex">
                <span class="grey--text font-25 font-weight-black"
                  >{{ $t("date") }} :</span
                >
                <span class="font-25 d-block mx-3 primary--text pl-2"
                  >{{ item.expected_date }}
                </span>
              </div>

              <div class="d-flex">
                <span class="grey--text font-25 font-weight-black"
                  >{{ $t("weight") }} :</span
                >
                <span class="font-25 d-block mx-3 primary--text pl-2"
                  >{{ item.weight + $t("kg") }}
                </span>
              </div>
              <div class="">
                <span class="grey--text font-25 font-weight-black"
                  >{{ $t("category") }} :</span
                >
                <div class="">
                  <span
                    class="font-15 category_item"
                    v-for="(category, j) in item.categories"
                    :key="j"
                    >{{ category.name }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-row v-if="isEdit" class="">
      <!-- <v-col v-if="!isEdit" cols="12" lg="5" style="flex-direction: column">
        <GmapMap
          class="mt-2 mb-6"
          :center="{ lat: 10, lng: 10 }"
          :zoom="10"
          style="width: 100%; height: 350px"
        >
        </GmapMap>
      </v-col> -->

      <!-- details -->
      <v-col cols="12">
        <!-- img & title -->
        <!-- trip title (country & edit) -->
        <span class="font-30 font-500 primary--text d-block mb-5" v-if="!isEdit"
          >{{ $t("trip") }} {{ $t("from") }} {{ item.origin_country }}
          {{ $t("to") }} {{ item.destination_country }}</span
        >
        <span
          class="font-30 font-500 primary--text d-block"
          :class="{ 'mb-4': isEdit }"
          v-if="isEdit"
        >
          {{ $t("editTrip") }}
        </span>

        <v-row align="center" v-if="isEdit">
          <!-- origin country  -->
          <v-col cols="9" md="6" class="py-2">
            <!-- value if not edit -->
            <span class="font-20 d-block black--text no-wrap font-500">{{
              $t("originCountry")
            }}</span>
            <base-select
              :placeholder="$t('originCountry')"
              v-model="obj.origin_country_id"
              :items="countries"
              @input="countryHandler($event, 'origin')"
            ></base-select>
          </v-col>

          <!-- destination country -->

          <v-col cols="9" md="6" class="py-2">
            <!-- value if not edit -->
            <span class="font-20 d-block black--text no-wrap font-500">{{
              $t("destinationCountry")
            }}</span>
            <base-select
              :placeholder="$t('destinationCountry')"
              v-model="obj.destination_country_id"
              :items="countries"
              @input="countryHandler($event, 'distination')"
            ></base-select>
          </v-col>
        </v-row>

        <v-row align="center">
          <!-- origin city  -->

          <v-col cols="9" md="6" class="py-2">
            <!-- value if not edit -->
            <span class="font-20 d-block black--text no-wrap font-500">{{
              $t(isEdit ? "originCity" : "from")
            }}</span>
            <base-select
              :placeholder="$t('originCity')"
              v-model="obj.origin_city_id"
              :items="originCities"
            ></base-select>
          </v-col>

          <!-- destination city -->

          <v-col cols="9" md="6" class="py-2">
            <!-- value if not edit -->
            <span class="font-20 d-block black--text no-wrap font-500">{{
              $t(isEdit ? "destinationCity" : "to")
            }}</span>
            <base-select
              :placeholder="$t('destinationCity')"
              v-model="obj.destination_city_id"
              :items="destinationCities"
            ></base-select>
          </v-col>

          <v-col cols="9" md="6" class="py-2">
            <!-- value if not edit -->
            <span class="font-20 d-block black--text no-wrap font-500">{{
              $t("date")
            }}</span>
            <base-date
              :placeholder="$t('date')"
              v-model="obj.expected_date"
              @handleDate="obj.expected_date = $event"
            ></base-date>
          </v-col>

          <v-col cols="9" md="6" class="py-2">
            <!-- value if not edit -->
            <span class="font-20 d-block black--text no-wrap font-500">{{
              $t("categories")
            }}</span>
            <base-select
              :placeholder="$t('category')"
              v-model="obj.categories"
              :items="categories"
              :multiple="true"
              itemValue="name"
              itemText="name"
            ></base-select>
          </v-col>

          <v-col cols="9" md="6" class="py-2">
            <!-- value if not edit -->
            <span class="font-20 d-block black--text no-wrap font-500">{{
              $t("weight")
            }}</span>

            <base-input
              :placeholder="$t('weight')"
              v-model="obj.weight"
              :isWeight="true"
              itemText="title"
              itemValue="title"
              type="number"
            ></base-input>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- cancel & save -->
    <div class="d-flex align-center justify-center mt-8" v-if="isEdit">
      <v-btn
        class="c-btn mx-1"
        color="secondary"
        height="45"
        min-width="200"
        @click="isEdit = false"
        ><span class="white--text font-20 font-300">{{
          $t("cancel")
        }}</span></v-btn
      >

      <v-btn
        class="c-btn mx-1"
        color="secondary"
        height="45"
        min-width="200"
        @click="saveHandler"
        ><span class="white--text font-20 font-300">{{
          $t("save")
        }}</span></v-btn
      >
    </div>

    <!-- accept dialog -->
    <v-dialog v-model="dialog" max-width="400" content-class="white">
      <delete-dialog
        @close="dialog = false"
        @ok="okHandler"
        url="/deleteTripByBringer"
        :id="{
          key: 'trip_id',
          id: $route.params.id,
        }"
      ></delete-dialog>
    </v-dialog>
  </div>
</template>

<script>
import DeleteDialog from "@/components/dialogs/DeleteShipment.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    DeleteDialog,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    obj: {},
    dialog: false,
    isEdit: false,
    originCities: [],
    destinationCities: [],
  }),
  computed: {
    ...mapGetters(["countries", "categories", "weightItems","loading_countries_categories"]),
  },
  methods: {
    editHandler() {
      this.isEdit = true;
      this.obj = { ...this.item };

      // origin
      this.obj.origin_country_id = +this.obj.origin_country_id;
      this.originCities = this.countries.filter(
        (country) => country.id == this.obj.origin_country_id
      )[0].cities;
      this.obj.origin_city_id = +this.obj.origin_city_id;

      // destination
      this.obj.destination_country_id = +this.obj.destination_country_id;
      this.destinationCities = this.countries.filter(
        (country) => country.id == this.obj.destination_country_id
      )[0].cities;

      this.obj.destination_city_id = +this.obj.destination_city_id;
    },
    countryHandler(event, type) {
      if (type == "distination") {
        this.destinationCities = this.countries.filter(
          (country) => country.id == event
        )[0].cities;
      } else {
        this.originCities = this.countries.filter(
          (country) => country.id == event
        )[0].cities;
      }
    },
    okHandler() {
      this.deleteDialog = false;
      this.$router.replace("/profile/trips");
    },
    async saveHandler() {
      let categories = [];
      this.categories.forEach((cat) => {
        this.obj.categories.forEach((inputCat) => {
          if (inputCat == cat.name || inputCat.name == cat.name) {
            categories.push(cat.id);
          }
        });
      });
      let formData = new FormData();

      formData.append("trip_id", this.obj.id);
      formData.append("origin_country_id", this.obj.origin_country_id);
      formData.append(
        "destination_country_id",
        this.obj.destination_country_id
      );
      formData.append("origin_city_id", this.obj.origin_city_id);
      formData.append("destination_city_id", this.obj.destination_city_id);
      formData.append("expected_date", this.obj.expected_date);
      formData.append("frequency", this.obj.frequency);
      formData.append("category_ids", "[" + categories + "]");
      formData.append("weight", this.obj.weight);

      await this.$axios.post("updateTrip", formData).then((res) => {
        if (res.data.success) {
          this.$store.dispatch("showSnack", {
            text: "Trip Updated Successfully",
            color: "success",
          });
          this.isEdit = false;
          this.$emit("reloadData");
        } else {
          this.$store.dispatch("showSnack", {
            text: res.data.message,
            color: "error",
          });
        }
      });
    },
  },
  created() {},
};
</script>

<style lang="scss">
.shipment-details {
  &__slide {
    img {
      width: 250px;
    }
  }
}
</style>

