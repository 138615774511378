<template>
  <div class="profile-trips-details">
    <div class="custom-tabs__tabs d-flex align-start justify-space-start">
      <div
        class="custom-tabs__tabs__tab text-center cursor-pointer w-30 mr-3 pt-2"
        :style="
          isMobile
            ? 'padding-left: 10px !important;padding-right: 10px !important;'
            : 'padding-left: 30px !important;padding-right: 30px !important;'
        "
        :class="tab.active ? 'custom-tabs__tabs__tab--active' : ''"
        v-for="(tab, i) in tabs"
        :key="i"
        @click="tabClicked(tab)"
      >
        <span
          class="font-25"
          :class="tab.active ? 'halfwhite--text' : 'grey--text'"
          >{{ $t(tab.title) }}</span
        >
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <component
        v-if="item.id"
        :is="selectedSection"
        class="mt-7"
        :item="item"
        @reloadData="getData"
        :loading="loading"
        v-model="refactiv"
      ></component>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DetailSection from "./components/DetailSection";
export default {
  components: {
    DetailSection,
  },
  data: () => ({
    refactiv: false,
    tabs: [
      {
        title: "details",
        active: true,
        component: () => import("./components/DetailSection"),
      },
      {
        title: "orders",
        active: false,
        component: () => import("./components/OrdersSection"),
      },
      {
        title: "rating",
        active: false,
        component: () => import("./components/RatingSection"),
      },
    ],
    selectedSection: () => import("./components/DetailSection"),
    item: { shipments: [] },
    loading: false,
  }),
  methods: {
    tabClicked(tab) {
      this.selectedSection = tab.component;
      this.tabs.forEach((t) => {
        if (t.title == tab.title) {
          t.active = true;
        } else {
          t.active = false;
        }
      });
    },
    async getData() {
      this.loading = true;
      let { data } = await this.$axios.get(`/myTrip/${this.$route.params.id}`);
      if (data.success) {
        this.item = data.data.trip;
        this.item.shipments = [
          ...data.data.trip.shipments.map((item) => {
            return {
              id: item.id,
              title: item.title,
              image: item.image1,
              images: [],
              fromCity: item.city_origin,
              toCity: item.city_destination,
              date: item.date,
              weight: item.weight,
              status: item.status,
              code_delivery: item.code_delivery,
              code_receive: item.code_receive,
              order_id: item.order_id,
            };
          }),
          ...data.data.trip.products.map((item) => {
            return {
              id: item.id,
              title: item.title,
              image: item.image,
              toCity: item.city_destination,
              date: item.date,
              weight: item.weight,
              status: item.status,
              code_delivery: item.code_delivery,
              code_receive: item.code_receive,
              order_id: item.order_id,
            };
          }),
        ];
      }
      this.loading = false;
    },

  },
  computed:{
    ...mapGetters(["selectedIdChate"]),
  },
  watch: {
    selectedIdChate(){
      this.getData();
    },
    async refactiv() {
      await this.getData();
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss" scoped>
.profile-trips-details {
  // @media (max-width: 500px) {
  //   &__tabs {
  //     &__tab {

  //     }
  //   }
  // }
  // @media (min-width: 500px) {
  //   &__tabs {
  //     &__tab {
  //       padding-left: 30px !important;
  //       padding-right: 30px !important;
  //     }
  //   }
  // }
}
</style>
